import React from 'react'
import Header from '../Header/Header'

function Test() {
  return (
    <>
    <Header/>
    <div>Test</div>
    </>
  )

}

export default Test